/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: "/login",
    view: "Login",
  },
  {
    path: "/your-stats",
    view: "YourStats",
    meta: {
      layout: "has-sidebar",
      requiresAuth: true,
    },
  },
  {
    path: "/review-reports",
    view: "ReviewReports",
    meta: {
      layout: "has-sidebar",
      permission: "reviewer",
      requiresAuth: true,
    },
  },
  {
    path: "/review-random",
    view: "ReviewRandom",
    meta: {
      layout: "has-sidebar",
      permission: "reviewer",
      requiresAuth: true,
    },
  },
  {
    path: "/review",
    view: "Review",
    meta: {
      layout: "has-sidebar",
      permission: "moderator",
      requiresAuth: true,
    },
  },
  {
    path: "/review/:id",
    view: "Report",
    meta: {
      layout: "has-sidebar",
      permission: "moderator",
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    view: "UserList",
    meta: {
      layout: "has-sidebar",
      permission: "moderator",
      requiresAuth: true,
    },
  },
  {
    path: "/users/:id",
    view: "User",
    meta: {
      layout: "has-sidebar",
      permission: "moderator",
      requiresAuth: true,
    },
  },
  {
    path: "/resolved",
    view: "Resolved",
    meta: {
      layout: "has-sidebar",
      permission: "moderator",
      requiresAuth: true,
    },
  },
  {
    path: "/set-roles",
    view: "Roles",
    meta: {
      layout: "has-sidebar",
      permission: "root",
      requiresAuth: true,
    },
  },
  {
    path: "/permissions",
    view: "Permissions",
    meta: {
      layout: "has-sidebar",
      permission: "root",
      requiresAuth: true,
    },
  },
  {
    path: "/featured",
    view: "Featured",
    meta: {
      layout: "has-sidebar",
      permission: "root",
      requiresAuth: true,
    },
  },
  {
    path: "/featured-moments",
    view: "FeaturedMoments",
    meta: {
      layout: "has-sidebar",
      permission: "root",
      requiresAuth: true,
    },
  },
  {
    path: "/ban-unban",
    view: "BanUnban",
    meta: {
      layout: "has-sidebar",
      permission: "moderator",
      requiresAuth: true,
    },
  },
  {
    path: "/approve",
    view: "Approve",
    meta: {
      layout: "has-sidebar",
      permission: "moderator",
      requiresAuth: true,
    },
  },
];
