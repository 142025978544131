import axios from "axios";
import Vue from "vue";
import store from "@/store/index";

import { getTokenFb } from '@/utils/firebase_responses.js';

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

// const tokenAxios = axios.create({
//   baseURL: process.env.VUE_APP_API_TOKEN,
// });
// Vue.prototype.$httpToken = tokenAxios;

// const adminAxios = axios.create({
//   baseURL: process.env.VUE_APP_API_ADMIN,
// });
// Vue.prototype.$httpAdmin = adminAxios;

// const userAxios = axios.create({
//   baseURL: process.env.VUE_APP_API_USER,
// });
// Vue.prototype.$httpUser = userAxios;

// const reportAxios = axios.create({
//   baseURL: process.env.VUE_APP_API_REPORT,
// });
// Vue.prototype.$httpReport = reportAxios;

// const momentAxios = axios.create({
//   baseURL: process.env.VUE_APP_API_MOMENT,
// });
// Vue.prototype.$httpMoment = momentAxios;

let isRefreshing = false;
let refreshSubscribers = [];

const createAxios = (baseURL) => {
  const newInstance = axios.create({ baseURL });

  newInstance.interceptors.request.use(
    async function(config) {
      // Do something before request is sent
      config.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  newInstance.interceptors.response.use(
     (config) => config,
     (error) => {
      const { config, response: { status } } = error;
      const originalRequest = config;
    
      if (status === 401 && !config.url.includes("login") && !config.url.includes("logout")) {
        if (!isRefreshing) {
          isRefreshing = true;
          getTokenFb(true)
            .then(getTokenFb => {
              isRefreshing = false;
              onRrefreshed(getTokenFb.token);
            });
        }
    
        const retryOrigReq = new Promise((resolve) => {
          subscribeTokenRefresh(token => {
            // replace the expired token and retry
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            resolve(axios(originalRequest));
          });
        });
        return retryOrigReq;
      } else {
        return Promise.reject(error);
      }
     }
   );

   return newInstance;
}

// instance.interceptors.response.use(response => {
//   return response;
// }, error => {
//   const { config, response: { status } } = error;
//   const originalRequest = config;

//   if (status === 498) {
//     if (!isRefreshing) {
//       isRefreshing = true;
//       getTokenFb()
//         .then(getTokenFb => {
//           isRefreshing = false;
//           onRrefreshed(getTokenFb.token);
//         });
//     }

//     const retryOrigReq = new Promise((resolve, reject) => {
//       subscribeTokenRefresh(token => {
//         // replace the expired token and retry
//         originalRequest.headers['Authorization'] = 'Bearer ' + token;
//         resolve(axios(originalRequest));
//       });
//     });
//     return retryOrigReq;
//   } else {
//     return Promise.reject(error);
//   }
// });

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
}

Vue.prototype.$httpToken = createAxios(process.env.VUE_APP_API_TOKEN)
Vue.prototype.$httpAdmin = createAxios( process.env.VUE_APP_API_ADMIN)
Vue.prototype.$httpUser = createAxios(process.env.VUE_APP_API_USER)
Vue.prototype.$httpReport = createAxios(process.env.VUE_APP_API_REPORT)
Vue.prototype.$httpMoment = createAxios(process.env.VUE_APP_API_MOMENT)

export default setAuthToken;
// export { tokenAxios, adminAxios };