// https://vuex.vuejs.org/en/mutations.html
import { initialState } from './state.js';

export default {
  resetStaff (state) {
    Object.assign(state, initialState);
  },
  setListStaff(state, list) {
    state.staff = list;
  },

  setListUsers(state, list) {
    state.listUsers = list;
  },

  setStaff(state, user) {
    const u = state.staff.find((item) => item.uid === user.uid);

    if (u === undefined) {
      state.staff.push(user);
    } else {
      Object.assign(u, user);
    }
  },

  deleteStaff(state, user) {
    let i = state.staff.map((item) => item.uid).indexOf(user.uid);
    state.staff.splice(i, 1);
  },

  updateUser(state, user) {
    delete user.firstName;
    const u = state.listUsers.find((item) => item.uid === user.uid);
    Object.assign(u, user);
  },
};
