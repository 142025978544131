export const initialState = {
  drawer: null,
  color: "active",
  image: "",
};
  
export default {
  ...JSON.parse(JSON.stringify(initialState))
};

