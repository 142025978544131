import Vue from "vue";
// import Router from "vue-router";
import VueRouter from 'vue-router';
import VeeValidate from "vee-validate";

// Routes
import paths from "./paths";

// Store
import store from "@/store/index.js";

function route(path, view, name, meta) {
  return {
    name: name || view,
    path,
    meta,
    component: (resovle) => import(`@/views/${view}.vue`).then(resovle),
  };
}

// Vue.use(Router);
Vue.use(VueRouter);
Vue.use(VeeValidate);

// const router = new Router({
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: paths
    .map((path) => route(path.path, path.view, path.name, path.meta))
    .concat([{ path: "*", redirect: "/login" }]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  console.log('Before each:') 
  console.log(`Route to`, to) 
  console.log(`Route from`, from) 
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      next();
      return;
      // if (to.path.includes("your-stats")) {
      //   next();
      //   return;
      // }

      // switch (to.meta.permission) {
      //   case "root":
      //     store.getters["user/roleID"] === 1 ? next() : next("/your-stats");
      //     return;
      //   case "moderator":
      //     store.getters["user/roleID"] <= 2 ? next() : next("/your-stats");
      //     return;
      //   case "reviewer":
      //     store.getters["user/roleID"] <= 3 ? next() : next("/your-stats");
      //     return;
      // }
    }
    next("/login");
  } else {
    next();
  }
});

// router.beforeResolve((to, from, next) => { 
//   console.log('Before resolve:') 
//   console.log(`Route to`, to) 
//   console.log(`Route from`, from) 
//   next();
// });

export default router;
