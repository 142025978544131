export const initialState = {
  isRetry: false,
  isRefreshing: false,
  token: localStorage.getItem("token") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  expiresTime: parseInt(localStorage.getItem("expiresTime")) || "",
  // expiresTime:1663068717700,
  isTokenExpired: false,
  isDone: JSON.parse(localStorage.getItem("isDone")) || null,
};
  
export default {
  ...JSON.parse(JSON.stringify(initialState))
};

