// https://vuex.vuejs.org/en/mutations.html

export default {
  startLoadingScreen (state, data) {
    state.waitingFor.push(data);
  },
    
  endLoadingScreen (state, data) {
    state.waitingFor = state.waitingFor.filter(w => w !== data);
  },
  
  addListReasons(state, reasons) {
    state.listReasons = reasons;
  },

};
