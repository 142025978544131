// https://vuex.vuejs.org/en/actions.html
import Vue from "vue";

export default {
  async getReport(context) {
    try {
      const response = await Vue.prototype.$httpReport.get("/random");

      if (response.status === 200 && response.data.success)
        context.commit("addReport", response.data.moment_report_to_assess);

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getAssessmentReport(context, id) {
    try {
      const response = await Vue.prototype.$httpReport.get("/result/", {
        params: {
          id: id,
        },
      });

      // if (response.status === 200 && response.data.success)
      //   context.commit("addReport", response.data.assessment_result);

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getPendingReports(context) {
    try {
      const response = await Vue.prototype.$httpReport.get(
        "/result/list/pending"
      );

      if (response.status === 200 && response.data.success)
        context.commit(
          "addPendingReports",
          response.data.list_assessment_result
        );

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getResolvedReports(context) {
    try {
      const response = await Vue.prototype.$httpReport.get(
        "/result/list/resolved"
      );

      if (response.status === 200 && response.data.success)
        context.commit(
          "addResolvedReports",
          response.data.list_assessment_result
        );

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async postAssessmentReport(context, payload) {
    try {
      let formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }

      const response = await Vue.prototype.$httpReport.post(
        "/result/resolve",
        formData,
        {
          headers: {
            "Content-Type": "multipart/x-www-form-urlencoded",
            "content-type": "multipart/form-data; boundary=${form._boundary}",
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getListViolator(context) {
    try {
      const response = await Vue.prototype.$httpReport.get("/violator/list");

      if (response.status === 200 && response.data.success)
        context.commit("addListViolator", response.data.violator_list);

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getViolator(context, id) {
    try {
      const response = await Vue.prototype.$httpReport.get("/violator/", {
        params: {
          id: id,
        },
      });

      if (response.status === 200 && response.data.success)
        context.commit("addViolator", response.data.violator);

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getAnalytics(context) {
    try {
      const response = await Vue.prototype.$httpReport.get("/analytics/");

      if (response.status === 200 && response.data.success)
        context.commit("addAnalytics", response.data.reviewer_stat);

      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
