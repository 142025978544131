// https://vuex.vuejs.org/en/mutations.html
import { initialState } from './state.js';

export default {
  resetAuth (state) {
    Object.assign(state, initialState);
  },
  logout(state) {
    state.token = "";
    state.refreshToken = "";
    state.expiresTime = "";
  },

  setRefreshedState(state, boolen) {
    state.isRetry = boolen;
  },

  setRefreshingState(state, boolen) {
    state.isRefreshing = boolen;
  },

  auth_success(state, { token, refreshToken, expiresTime, isDone }) {
    state.token = token;
    state.refreshToken = refreshToken;
    state.expiresTime = expiresTime;
    state.isDone = isDone;
  },

  setExpiredToken(state, isTokenExpired) {
    state.isTokenExpired = isTokenExpired;
  },
};
