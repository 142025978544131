// https://vuex.vuejs.org/en/actions.html
import Vue from "vue";

export default {
  async checkRole({ commit }) {
    try {
      const response = await Vue.prototype.$httpAdmin.get("/role/check");

      if (response.status == 200 && response.data.success) {
        commit("user/setRole", response.data.role, { root: true });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getListStaff({ commit }) {
    try {
      const response = await Vue.prototype.$httpAdmin.get("/role/users");

      if (response.status === 200 && response.data.success) {
        commit("setListStaff", response.data.user_profile);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async submitStaffRoles({ commit }, payload) {
    try {
      let formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }
      formData.delete("action");

      const response = await Vue.prototype.$httpAdmin.post("/role/", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200 && response.data.success) {
        commit("setStaff", response.data.user_profile);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async deleteStaffRoles({ commit }, payload) {
    try {
      let formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }

      const response = await Vue.prototype.$httpAdmin.post("/role/", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200 && response.data.success) {
        commit("deleteStaff", response.data.user_profile);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getListUserByUsername({ commit }, username) {
    try {
      let formData = new FormData();
      formData.append("username", username);

      const response = await Vue.prototype.$httpAdmin.post(
        "/search",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200 && response.data.success)
        commit("setListUsers", response.data.list_user);

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async submitUserPermissions({ commit }, payload) {
    try {
      let formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }

      const response = await Vue.prototype.$httpAdmin.post(
        "/permission/",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200 && response.data.success) {
        commit("updateUser", response.data.user_profile);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
