import { firebaseTrivia, auth } from '@/firebaseConfig.js';
import { signInWithPhoneNumber, RecaptchaVerifier, getRedirectResult, onAuthStateChanged, getIdTokenResult } from "firebase/auth";
import store from '@/store/index.js';


export async function getTokenFb (forceRefresh) {
  try {
    // const resultUser = await firebaseTrivia.auth().currentUser;
    console.log('get token');
    const resultToken = forceRefresh ? await getIdTokenResult(store.state.user.userFb, forceRefresh) : await getIdTokenResult(store.state.user.userFb,);

    const token = resultToken.token;
    // const refreshToken = user.refreshToken;

    const expiresTime = new Date(resultToken.expirationTime).getTime() - 300;

    localStorage.setItem('token', token);
    // localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expiresTime', expiresTime);
    localStorage.setItem('isDone', true);
   
    store.commit('auth/auth_success', {
      token,
      refreshToken: null,
      expiresTime,
      isDone: true
    });


    if (forceRefresh) {
      store.commit("auth/setRefreshingState", false);
    }

    return resultToken;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default {
  data () {
    return {};
  },
  methods: {
    getTokenFb,
    
    async getAuthFbChange () {
      try {
        

        await onAuthStateChanged(auth, (user) => {
          console.log(user)
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            console.log(user);
            
          } else {
            // User is signed out
          }

          return user;
        });

      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async getCurrentUser () {
      try {
        const user = await auth.currentUser;

        if (user) {
          console.log(user);
        } 

        return user;

      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async initReCaptcha () {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
          'size': 'invisible',
          'callback': (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
            console.log(response)
          }
        }, auth);
        return window.recaptchaVerifier;
      } catch (error) {
        console.log(error);
        return error;
      }
      
    },

    async submitSignInWithPhoneNumber (phoneNumber, appVerifier) {
      try {
        const response = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
        
        return response;

      } catch (error) {
        console.log(error);
        throw error;
      }
      
    },

    async submitCodeSignInWithPhoneNumber (confirmationResult, code) {
      try {
        const responseConfirmationResult = await confirmationResult.confirm(code);
        const resultUser = responseConfirmationResult.user;

        // const resultIdToken = await this.getTokenFb(resultUser);

        // return {resultUser, resultIdToken};
        return resultUser;
          
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async submitSignInWithGooglePopup (provider) {
      try {
        const resultUser = await firebaseTrivia.auth()
          .signInWithPopup(provider);

        const resultToken = await this.getTokenFb(resultUser);
        
        return {resultUser, resultToken};

      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async submitSignInWithGoogleRedirect (provider) {
      try {
        // const resultUser = await firebaseTrivia.auth()
        //   .signInWithRedirect(provider);

        // const resultToken = await this.getTokenFb(resultUser);
        
        return await firebaseTrivia.auth().signInWithRedirect(provider);

      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async getRedirectFbResult () {
      try {
        const resultRedirect = await getRedirectResult(auth);
        const resultUser = resultRedirect.user || auth.currentUser ? resultRedirect.user : null;

        const resultToken = await this.getTokenFb(resultUser);
        
        return {resultUser, resultToken};

      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    // async getTokenFb (user, forceRefresh) {
    //   try {
    //     // const resultUser = await firebaseTrivia.auth().currentUser;
    //     console.log('get token');
    //     const resultToken = await auth.currentUser.getIdTokenResult(forceRefresh);

    //     const token = resultToken.token;
    //     const refreshToken = user.refreshToken;

    //     const expiresTime = new Date(resultToken.expirationTime).getTime() - 300;

    //     localStorage.setItem('token', token);
    //     localStorage.setItem('refreshToken', refreshToken);
    //     localStorage.setItem('expiresTime', expiresTime);
    //     localStorage.setItem('isDone', true);
       
    //     store.commit('auth/auth_success', {
    //       token,
    //       refreshToken,
    //       expiresTime,
    //       isDone: true
    //     });

    //     return resultToken;
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // },

    // async refreshFbToken () {
    //   try {

    //     const response = await axios.post(
    //       'https://securetoken.googleapis.com/v1/token',
    //       {
    //         grant_type: 'refresh_token',
    //         refresh_token:store.state.auth.refreshToken
    //       },
    //       { params: {
    //         key: process.env.VUE_APP_GG_API_KEY
    //       }},
    //       {
    //         headers: {
    //           'Content-Type': 'application/x-www-form-urlencoded'
    //         }
    //       }
    //     );

    //     if (response.status === 200) {
    //       const dataToken = response.data;

    //       const token = dataToken.id_token;
    //       const refreshToken = dataToken.refresh_token;
  
    //       const timeObject = new Date();
    //       timeObject.setSeconds(
    //         timeObject.getSeconds() + (response.data.expires_in - 300)
    //       // timeObject.getSeconds() + 100
    //       );
    //       const expiresTime = timeObject.getTime();
  
    //       localStorage.setItem('token', token);
    //       localStorage.setItem('refreshToken', refreshToken);
    //       localStorage.setItem('expiresTime', expiresTime);
    //       localStorage.setItem('isDone', true);
         
    //       store.commit('auth/auth_success', {
    //         token,
    //         refreshToken,
    //         expiresTime,
    //         isDone: true
    //       });
    //     }

    //     console.log(response);

    //     return response;
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // }
  }
};
  