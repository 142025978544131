// https://vuex.vuejs.org/en/actions.html
import Vue from "vue";

import {  auth } from '@/firebaseConfig.js';
import router from "@/router";

export default {
  async getProfile({ commit }) {
    try {
      const response = await Vue.prototype.$httpUser.get("/profile/me");

      if (response.status == 200 && response.data.success) {
        commit("profileMe", response.data.user_profile);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getProfileOther({ commit }, uid) {
    try {
      const response = await Vue.prototype.$httpUser.get(
        `/profile/other/${uid}`
      );

      if (response.status == 200 && response.data.success) {
        commit("profileOther", response.data.user_profile);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async logout({ dispatch, rootState }) {
    try {
      // const response = await Vue.prototype.$httpUser.post("/logout");

      // console.log(response);
      // if (response.status == 200 && response.data.success) {
      // commit("auth/logout", null, { root: true });
      // localStorage.clear();
      // delete Vue.prototype.$httpAdmin.defaults.headers.common["Authorization"];
      // delete Vue.prototype.$httpUser.defaults.headers.common["Authorization"];
      // }
      const user = auth.currentUser;

      const p1 =  rootState.user.token ? Vue.prototype.$httpUser.post('/logout') : null;
      const p2 = user ? auth.signOut() : null;

      const response = await Promise.all([p1, p2]);

      dispatch('resetAllState', null, { root: true });
      
      router.push('/login', () => {});

      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
