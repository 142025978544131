// https://vuex.vuejs.org/en/actions.html
import Vue from "vue";

export default {
  resetAllState ({ commit }) {
    commit('system/resetSystem', null, { root: true });
    commit('staff/resetStaff', null, { root: true });
    commit('report/resetReport', null, { root: true });
    commit('moment/resetMoment', null, { root: true });
    commit('app/resetApp', null, { root: true });
    commit('user/resetUser', null, { root: true });
    commit('auth/resetAuth', null, { root: true });
  },

  async getListReasons(context) {
    try {
      const response = await Vue.prototype.$httpReport.get("/reason/list");

      if (response.status === 200 && response.data.success)
        context.commit(
          "addListReasons",
          response.data.list_moment_report_reason
        );

      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
