// https://vuex.vuejs.org/en/actions.html
import Vue from "vue";

export default {
  async exchangeToken({ commit }, phoneToken) {
    try {
      let formData = new FormData();
      formData.set("token", phoneToken);

      const response = await Vue.prototype.$httpToken.post(
        "/exchange",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status == 200 && response.data.success) {
        const token = response.data.id_token;
        const refreshToken = response.data.refresh_token;
        // const expiresTime = Vue.prototype
        //   .$moment()
        //   .add(response.data.expires_in - 300, "seconds")
        //   .unix();
        let timeObject = new Date();
        timeObject.setSeconds(
          timeObject.getSeconds() + (response.data.expires_in - 300)
        );
        const expiresTime = timeObject.getTime();

        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresTime", expiresTime);
        localStorage.setItem("isDone", true);

        commit("auth_success", {
          token,
          refreshToken,
          expiresTime,
          isDone: true,
        });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async refreshToken({ commit, state }) {
    try {
      commit("setRefreshingState", true);

      let formData = new FormData();
      formData.set("token", state.refreshToken);

      const response = await Vue.prototype.$httpToken.post(
        "/refresh",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200 && response.data.success) {
        const token = response.data.id_token;
        const refreshToken = response.data.refresh_token;

        // const expiresTime = Vue.prototype
        //   .$moment()
        //   .add(response.data.expires_in - 300, "seconds")
        //   .unix();

        let timeObject = new Date();
        timeObject.setSeconds(
          timeObject.getSeconds() + (response.data.expires_in - 300)
        );
        const expiresTime = timeObject.getTime();

        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresTime", expiresTime);
        localStorage.setItem("isDone", true);

        // Vue.prototype.$httpAdmin.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${token}`;
        // Vue.prototype.$httpUser.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${token}`;

        commit("auth_success", {
          token,
          refreshToken,
          expiresTime,
          isDone: true,
        });
        commit("setRefreshingState", false);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  checkToken({ commit, state }) {
    let check = new Date().getTime() > state.expiresTime;

    commit("setExpiredToken", check);
  },
};
