// https://vuex.vuejs.org/en/actions.html
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";

export default {
  async searchMoments({ commit }, payload) {
    try {
      const response = await Vue.prototype.$httpMoment.get("/search", {
        params: {
          p: payload.page,
          n: payload.search,
          q: payload.quanlity,
        },
      });

      if (response.status === 200 && response.data.success) {
        commit("setListMoments", response.data.moments);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getListFeaturedMoments({ commit }, payload) {
    try {
      const response = await Vue.prototype.$httpMoment.get("/moments", {
        params: {
          page: payload.page,
          pageSize: payload.pageSize,
          status: "APPROVE",
          tag: "featured",
        },
        headers: {
          "X-Request-Id": uuidv4(),
        },
      });

      if (response.status === 200) {
        commit("setListFeaturedMoments", response.data.data);
      }

      console.log("getListFeaturedMoments");
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async gethMoments({ commit }, payload) {
    try {
      const response = await Vue.prototype.$httpMoment.get("/moments", {
        params: {
          page: payload.page,
          pageSize: payload.pageSize,
          status: "pending",
        },
        headers: {
          "X-Request-Id": uuidv4(),
        },
      });

      if (response.status === 200) {
        commit("setListMoments", response.data.data);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
