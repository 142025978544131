import Vue from "vue";

// Components
import "./components";

// Plugins
import "./plugins";

import App from "./App.vue";

import router from "@/router";
import store from "@/store";
import vuetify from './plugins/vuetify';
// import "./registerServiceWorker";
import './firebaseConfig.js';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
