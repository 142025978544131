// https://vuex.vuejs.org/en/actions.html
import Vue from "vue";

export default {
  async getListBan({ commit }) {
    try {
      const response = await Vue.prototype.$httpAdmin.get("/system/ban/list");

      if (response.status == 200 && response.data.success) {
        commit("setListBan", response.data.user_profiles);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
