// https://vuex.vuejs.org/en/mutations.html
import Vue from "vue";
import { initialState } from './state.js';

export default {
  resetMoment (state) {
    Object.assign(state, initialState);
  },
  setListMoments(state, list) {
    // state.listMoments = state.listMoments.concat(list);
    // state.listMoments = Vue.prototype.$unionby(state.listMoments, list, "id");
    state.listMoments = list;
  },

  setListFeaturedMoments(state, list) {
    state.listFeaturedMoments = Vue.prototype.$unionby(
      state.listFeaturedMoments,
      list,
      "id"
    );
  },

  clearListFeaturedMoments(state) {
    state.listFeaturedMoments = {};
  },

  clearListMoments(state) {
    state.listMoments = {};
  },
};
