// https://vuex.vuejs.org/en/getters.html

export default {
  roleID: (state) => {
    let id = null;
    switch (state.role) {
      case "NORMAL_USER":
        id = 0;
        break;
      case "ADMIN":
        id = 1;
        break;
      case "MODERATOR":
        id = 2;
        break;
      case "REVIEWER":
        id = 3;
        break;
    }

    return id;
  },
};
