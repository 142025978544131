export const initialState = {
  report: {},
  pendingReports: [],
  resolvedReports: [],
  listViolator: [],
  violator: {},
  analytics: {},
};
  
export default {
  ...JSON.parse(JSON.stringify(initialState))
};
  

