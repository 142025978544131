// https://vuex.vuejs.org/en/mutations.html
import { initialState } from './state.js';

export default {
  resetReport (state) {
    Object.assign(state, initialState);
  },
  addReport(state, report) {
    Object.assign(state.report, report);
  },

  addPendingReports(state, reports) {
    state.pendingReports = reports;
  },

  addResolvedReports(state, reports) {
    state.resolvedReports = reports;
  },

  addListViolator(state, reports) {
    state.listViolator = reports;
  },

  addViolator(state, violator) {
    state.violator = violator;
  },

  addAnalytics(state, analytics) {
    Object.assign(state.analytics, analytics);
  },
};
