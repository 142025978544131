// https://vuex.vuejs.org/en/mutations.html
import { initialState } from './state.js';

export default {
  resetSystem (state) {
    Object.assign(state, initialState);
  },
  setListBan(state, list) {
    state.banList = list;
  },
};
