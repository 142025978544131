import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#E85113",
  accent: "#DB5B36",
      },
      dark: {
        primary: "#E85113",
  accent: "#DB5B36",
      }
    }
  },
  icons: {
    // iconfont: 'md',
    //   iconfont: 'mdi',
    values: {
      candy: {
        component: Vue.component("IconsCandy"),
      },
      edit_profile: {
        component: Vue.component("IconsEditProfile"),
      },
      close: {
        component: Vue.component("IconsClose"),
      },
      configure: {
        component: Vue.component("IconsConfigure"),
      },
      circle_plus: {
        component: Vue.component("IconsCirclePlus"),
      },
    }
  }
};

export default new Vuetify(opts);
