// https://vuex.vuejs.org/en/mutations.html
import { initialState } from './state.js';

export default {
  resetUser (state) {
    Object.assign(state, initialState);
  },
  logout(state) {
    state.status = "";
    state.token = "";
    state.refreshToken = "";
    state.expiresTime = "";
  },

  profileMe(state, profile) {
    state.user = profile;
  },

  setUserFb(state, user) {
    state.userFb = user;
  },

  profileOther(state, profile) {
    state.other = profile;
  },

  requestFriendTo(state, requests) {
    state.requestFriendTo = requests;
  },

  setRole(state, role) {
    state.role = role;
  },
};
