<template>
  <v-app>
    <component
      :is="layout"
      v-if="!waitingFor.length"
    >
      <router-view />
    </component>
  </v-app>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

import { auth } from '@/firebaseConfig.js';
import { onAuthStateChanged } from "firebase/auth";
import { getTokenFb } from '@/utils/firebase_responses.js';

export default {
  mixins: [],
  computed: {
    ...mapState("user", ["user", "role", "userFb"]),
    ...mapState(['waitingFor']),
    ...mapGetters("user", ["roleID"]),
    layout() {
      return "core-" + (this.$route.meta.layout || "default");
    },
  },
  async created() {

    this.startLoadingScreen("getCurrentUser")
    // const response = await this.getAuthFbChange();

    onAuthStateChanged(auth, async (user) => {
      console.log(user)
      if (user) {
        this.setUserFb(user);
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        await getTokenFb();

        if (!Object.keys(this.user).length) {
          await this.getProfile();
          if (Object.keys(this.user).length && this.user.firstName) {
            await this.checkUserAndRedirect();
          }
        }

      } else {
        // User is signed out
        if (Object.keys(this.user).length && this.user.firstName) {
          await this.logout();
        }

      }
      this.endLoadingScreen("getCurrentUser")

    });
  },
  watch: {
    '$route': {
      handler: function (value) {
        console.log(value)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['startLoadingScreen', 'endLoadingScreen']),
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    ...mapMutations("user", ["setUserFb"]),
    ...mapActions("user", ["getProfile", "logout"]),
    ...mapActions("staff", ["checkRole"]),

    async checkUserAndRedirect() {
      if (!this.role) {
        await this.checkRole()
      }

      if (this.role === "NORMAL_USER") {
        await this.logout()
      }


      switch (this.$route.meta.permission) {
        case "root":
          if (this.roleID === 1) {
            return;
          } else {
            this.$router.push("/your-stats");
            return;
          }
        case "moderator":
          if (this.roleID <= 2) {
            return;
          } else {
            this.$router.push("/your-stats");
            return;
          }
        case "reviewer":
          if (this.roleID <= 3) {
            return;
          } else {
            this.$router.push("/your-stats");
            return;
          }
      }
    },
  }

};
</script>

<style lang="scss">
// Import Main styles for this application
@import "./assets/scss/tesuji.scss";
</style>
