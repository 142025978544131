import { set, toggle } from "@/utils/vuex";
import { initialState } from './state.js';

export default {
  resetApp (state) {
    Object.assign(state, initialState);
  },
  setDrawer: set("drawer"),
  setImage: set("image"),
  setColor: set("color"),
  toggleDrawer: toggle("drawer"),
};
