import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import { getAuth } from 'firebase/auth';

// import Vue from 'vue';

const configFirebase = {
  apiKey: `${process.env.VUE_APP_FIREBASE_VRUMBLE_API_KEY}`,
  authDomain: `${process.env.VUE_APP_FIREBASE_VRUMBLE_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.VUE_APP_FIREBASE_VRUMBLE_ID}.firebaseio.com`,
  projectId: `${process.env.VUE_APP_FIREBASE_VRUMBLE_ID}`,
  storageBucket: `${process.env.VUE_APP_FIREBASE_VRUMBLE_ID}.appspot.com`,
  messagingSenderId: `${process.env.VUE_APP_FIREBASE_VRUMBLE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.VUE_APP_FIREBASE_VRUMBLE_APP_ID}`
  // measurementId: `${process.env.VUE_APP_FIREBASE_TRACKS_MEASUREMENT_ID}`
};
export const firebaseTrivia = initializeApp(configFirebase);

export const auth = getAuth(firebaseTrivia);

