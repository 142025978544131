export const initialState = {
  staff: null,
  listUsers: null,
};
  
export default {
  ...JSON.parse(JSON.stringify(initialState))
};
  

