export const initialState = {
  user: {},
  other: {},
  role: null,
  userFb: null
};
  
export default {
  ...JSON.parse(JSON.stringify(initialState))
};
  